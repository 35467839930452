<template>
    <div class="order-delivery">
        <div class="card">
            <div class="card-body px-lg-6">
                <div v-if="delivery" class="mb-4">
                    <div class="pb-3 border-bottom">
                        <div>
                            <h3 class="font-weight-bold">Delivered Files</h3>
                            <delivery-files :files="delivery.files" />
                        </div>
                        <div class="mt-1">
                            <p class="font-sm text-white text-muted mb-0">
                            {{delivery.created_at | date_ago}}
                            </p>
                        </div>
                        <div v-if="delivery.status != 'PROGRESS'" class="border-top mt-2 pt-2">
                            <h6 class="font-weight-bold">Notice:</h6>
                            <ol class="mb-0">
                                <li v-if="delivery.status == 'WINNER'" class="text-success"><strong>Congrats!</strong> You have won this contest.</li>
                                <li v-if="delivery.status == 'BLOCKED'" class="text-danger">You have been blocked from this contest</li>
                                <li v-if="delivery.status == 'ELIMINATED'" class="text-danger">You have been eliminated from this contest</li>
                                <li v-if="delivery.status == 'FINALIST'" class="text-info">You have been selected as a finalist</li>
                                <li v-if="delivery.is_reviewed">The client has made a review on your delivery</li>
                            </ol>
                        </div>
                    </div>
                    <div class="py-3 border-bottom" v-if="delivery.is_reviewed">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item mr-1" v-for="star in parseInt(delivery.review.rating)" :key="'0-'+star">
                                <img :src="absoluteUrl('/images/svg/star.svg')" alt="Review rating" width="12"></li>
                            <li class="list-inline-item mr-1" v-for="nostar in  ((delivery.review.rating % 1 == 0 ? 5 : 4) - parseInt(delivery.review.rating))" :key="nostar">
                                <img :src="absoluteUrl('/images/svg/star-muted.svg')" alt="Review rating" width="12"></li>
                        </ul>
                        <p class="mb-0 text-muted">{{delivery.review.content}}</p>
                    </div>
                </div>
                <delivery-unit />
            </div>
        </div>
        <div>
            <delivery-messages />
        </div>
    </div>
</template>

<script>
import DeliveryMessages from './DeliveryMessages.vue';
import DeliveryFiles from './DeliveryFiles.vue';
import DeliveryUnit from "./DeliveryUnit.vue";
export default {
  components: {
    DeliveryUnit,
    DeliveryFiles,
    DeliveryMessages,
  },
  computed:{
      delivery(){
        return this.$store.state.orderList.order.delivery
      }
  }
};
</script>
