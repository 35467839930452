<template>
  <div class="photo-gallery-card">
    <div :class="{'selected': selected}"
        class="photo-type p-2 bg-white">
        <img :src="absoluteUrl(photoType.value)" class="photo-type-img w-100">
    </div>
    <div class="photo-type-name pt-1">
        <h6 class="mb-1">{{photoType.name}}</h6>
        <!-- <small style="font-size:12px;line-height:1;">{{photoType.description}}</small> -->
    </div>
  </div>
</template>

<script>
export default {
    props:{
        photoType:{
            type:Object,
            required: true
        },
        selected:{
            type: Boolean,
            default: false
        }
    }
    
}
</script>

<style>
.photo-gallery-card .photo-type {
    position: relative;
}
.photo-gallery-card .photo-type{
    border: 3px solid #edecec;
    background: var(--bs-light);
    transition: border-width 0.3s ease-in;
}
.photo-gallery-card  .photo-type.selected .color-row{
    border-color: var(--bs-success);
}
.photo-gallery-card .photo-type.selected{
    border-color: var(--bs-success);
}
</style>