<template>
  <div class="order-brief">
    <package-features v-if="order.product_id" :item="order.package"
     :attributes="order.attributes" class="mb-4" />

    <plan-features v-if="order.task_id" :item="order.plan"
     :attributes="order.attributes" class="mb-4" />

    <addons v-if="order.product_id" :addons="cart.addons" class="mb-4" />

    <requirements class="mb-4" />

    <div>
      <h4 class="mb-0 text-primary">More Description</h4>
      <hr class="mt-1"/>
      <div v-html="order.description">
        
      </div>
      <p v-if="!order.description" class="text-muted"><em>no description to show</em></p>
    </div>
  </div>
</template>

<script>
import Requirements from "./Requirements.vue"
import Addons from "./Addons.vue"
import PackageFeatures from "./PackageFeatures.vue"
import PlanFeatures from "./PlanFeatures.vue"

export default {
  components:{
    Addons,
    Requirements,
    PackageFeatures,
    PlanFeatures
  },
  computed:{
    order(){
      return this.$store.state.orderList.order
    },
    item(){
      return this.order.product || this.order.task
    },
    cart(){
      return this.order.cart
    },
  },
}

</script>

