<template>
    <div class="row delivery-unit">
        <div class="col-md-12">
            <div class="">
                <vue-dropzone id="deliveryDropZone" ref="deliveryDropZone" 
                    :use-custom-slot="true" :maxFile="10"
                    acceptedFiles="image/*,.ai,.eps,.psd,.zip,.pdf,application/zip">
                    <div class="needsclick m-0">
                        <h4>Drop files here or click to upload.</h4>
                        <span class="text-muted font-13">
                        (Ai, video files should be zipped)
                        </span>
                    </div>
                </vue-dropzone>
            </div>
        </div>
        <div class="col-12">
            <div class="">
                <button @click.prevent="deliverFiles()" type="button"
                 class="btn btn-success mt-2"> Submit </button>
            </div>
        </div>
    </div>
</template>
<script>
import VueDropzone from '@/components/VueDropzone.vue';
export default {
    components: {
        VueDropzone,
    },
    computed:{
        order(){
            return this.$store.state.orderList.order
        }
    },
    methods:{
        deliverFiles(){
            if(!this.collectFiles().length){ this.alertError("At least a file is Required"); return;}
            let formData = new FormData();
            this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/deliveries/${this.order.id}/create`,formData)
                .then(response => {
                    this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.initializeAll()
                            this.$store.commit('orderList/UPDATE_DELIVERY', response.data.data)
                        }
                })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.deliveryDropZone){
                arrafile = [...this.$refs.deliveryDropZone.getAcceptedFiles()];
            }
            return arrafile
        },
        initializeAll(){
            if(this.$refs.deliveryDropZone){
                this.$refs.deliveryDropZone.removeAllFiles();
            }
        }
    }
}
</script>
