<template>
  <div class="order-show" >
    <div v-if="!isLoading" class="row">
      <div class="col-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb pt-0">
            <li class="breadcrumb-item"><router-link to="/orders">Orders</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">View order</li>
          </ol>
        </nav>
      </div>
      <div class="col-lg-12">
        <div class="card card-body">
          <div class="row">
            <div class="col-md-6 col-lg-4 mb-2 mb-md-0">
              <div class="mb-2"> <span class="">Order No: <strong>#{{order.number}}</strong></span> </div>
              <div class="mb-0">Status: <strong class="text-uppercase">{{order.status}}</strong>  </div>
            </div>
            <div class="col-md-6 col-lg-4 mb-2 mb-md-0">
              <div class="mb-2">Item: <strong class="text-uppercase">{{item.name}}</strong> </div>
              <div class="mb-0">Date: <strong class="text-uppercase">{{ order.created_at | date_time(1) }}</strong> </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-2">Delivery date: <strong class="text-uppercase">{{ order.created_at | deliveryDate(order.delivery_time)}}</strong> </div>
              <div class="mb-0">Duration: <strong class="text-uppercase">{{calculateDeliveryTime(order.delivery_time)}}</strong> </div>
            </div>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-12">
            <div class="d-flex">
              <div class="soft-bread w-auto overflow-hidden border shadow-sm">
                <span class="soft-bread-item border-right"
                  :class="{'bg-success text-white': order.stage == 'design'}"><span style="z-index: 2;">DESIGN</span></span>
                  <span class="soft-bread-item border-right"
                  :class="{'bg-success text-white': order.stage == 'finalist'}"><span style="z-index: 2;">FINALIST</span></span>
                <span class="soft-bread-item"
                :class="{'bg-success text-white': order.stage == 'finished'}"><span style="z-index: 2;">FILES</span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10">
            <b-tabs nav-class="nav-tabs nav-bordered">
              <b-tab :active="activeTab === 1">
                <template v-slot:title>
                  <i class="mdi mdi-book-outline mr-1"></i>
                  <span class="nav-label">Brief</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <order-brief />
                  </div>
                </div>
              </b-tab>
              <b-tab :active="activeTab === 2">
                <template v-slot:title>
                  <i class="mdi mdi-file-image mr-1"></i>
                  <span class="nav-label">Delivery</span>
                </template>
                <div>
                  <order-delivery />
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <is-loading v-else />
  </div>
</template>

<script>
import OrderDelivery from "@/components/order/OrderDelivery.vue"
import OrderBrief from "@/components/order/OrderBrief.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "order-show",
  components: {
    OrderDelivery,
    OrderBrief,
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    item(){
      return this.order.product || this.order.task
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 1;
    },
  },
  methods:{
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: '/error-404'})
        }
      })
    },
  },
  created(){
    this.fetchOrder()
  },

}
</script>

<style lang="scss">
.order-show {
  .vs-tabs--content {
    padding: 0;
  }
}
.order-show .soft-bread{
  font-weight: 600;
  background: white
}

.order-show .soft-bread .soft-bread-item{
    display:inline-block;
    color:black;
    padding: 0.2rem 0.8rem;
}
</style>
