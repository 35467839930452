<template>
  <div v-if="addons.length" class="">
    <h4 class="mb-0 text-primary">Extra Services</h4>
    <hr class="mt-1"/>
    <div class="row">
      <div class="col-12">
        <ul class="list-unstyled">
          <template v-for="(addon, adIndex) in addons">
            <li :key="adIndex"
              class="p-2 mb-3 shadow-sm p-md-3 bg-light d-block">
              <div>
                <h5 class="mb-0" >{{addon.name}}</h5>
                <p class="mb-0"> {{addon.description}}</p>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
</div>
</template>

<script>
export default {
  props:{
    addons:{
      type:Array,
      default: () => []
    }
  }
}
</script>
